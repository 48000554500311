import React, {Fragment, lazy, Suspense} from 'react';
import * as ReactDOM from 'react-dom/client';
import uuid from 'react-uuid';

const AUTHOR_LAYER_CLASS = 'aem-AuthorLayer-Edit';
const RENDERED_CLASS = 'rendered';
const REACT_ROOT_CANDIDATE_SELECTOR = '.react-component';
const ATTR_RESOURCE_TYPE = 'data-resource-type';
const ATTR_LAZY_LOAD = 'data-lazy';
const ATTR_ROOT_ID = 'data-root-id';

class ReactLoader {
    #insideEditor;

    #reactRootCandidates;

    #reactRootStore;

    #intersectionObserver;

    #globalNotifier;

    #globalNotificationTimeout;

    constructor() {
        this.intersectionHandler = this.intersectionHandler.bind(this);
        this.bootstrap = this.bootstrap.bind(this);
        this.render = this.render.bind(this);
        this.intersectionObserver = new IntersectionObserver(this.intersectionHandler);
        this.reactRootStore = [];
        this.insideEditor = document.documentElement.classList.contains(AUTHOR_LAYER_CLASS) || window !== window.top;
        this.reactRootCandidates = document.querySelectorAll(REACT_ROOT_CANDIDATE_SELECTOR);
        this.bootstrap();
    }

    /**
     * Creates a new React Root for each candidate
     *
     * All candidates are determined in the constructor and passed to the "render" method.
     * If no resource type has been passed, the process for the component is aborted.
     * If the attribute "lazy" was passed to the component, the component is first passed to the intersection observer.
     * If the class is called within the editor, lazy loading will be ignored.
     *
     * Recreates the root if DOM is updated inside AEM Editor
     * @param el - the React candidate
     * @param io - the IntersectionObserver
     */
    bootstrap() {
        this.reactRootCandidates.forEach((candidate) => {
            if (!candidate.hasAttribute(ATTR_RESOURCE_TYPE)) {
                console.error(`Error bootstrapping React Component: No attribute "${ATTR_RESOURCE_TYPE}" found`);
                return;
            }
            if (this.insideEditor) {
                document.addEventListener('editable-updated', () => {
                    Array
                        .from(document.querySelectorAll(`${REACT_ROOT_CANDIDATE_SELECTOR}:not(${RENDERED_CLASS})`))
                        .forEach((newCandidate) => this.render(newCandidate));
                });
            }
            if (!this.insideEditor && candidate.hasAttribute(ATTR_LAZY_LOAD)) {
                this.intersectionObserver.observe(candidate);
                return;
            }
            this.render(candidate);
        });
    }

    /**
     * The name of the component is determined on the basis of the resource type.
     * This results in the path to the JavaScript file. (which ends with .react.js)
     * The call of the "import" method with the path to the JavaScript file and the comments above it instruct Webpack to split off chunks of code here.
     * In order for React to properly handle the loaded file, the call is passed as a parameter to the "lazy" method.
     * In the last step, the component is rendered onto the candidate (which is transformed to a react root via the "createRoot" method)
     *
     * @param element - the React candidate
     */
    render(element) {
        const {resourceType} = element.dataset;
        const resourceName = resourceType.substring(resourceType.lastIndexOf('/') + 1);
        /* webpackInclude: /\.react.js$/ */
        /* webpackExclude: /__tests__|utils[.]jest|clientlibs-author|editor/ */
        /* webpackMode: "lazy-once" */
        const Component = lazy(() => import(`Apps/${resourceType}/${resourceName}.react.js`));
        this.createRoot(element).render(<Suspense fallback={<Fragment/>}><Component {...element.dataset} /></Suspense>);
        element.classList.add(RENDERED_CLASS);
    }

    /**
     * In this method, a HTML element is transformed into a React root.
     *
     * @param element
     * @returns {Root}
     */
    createRoot(element) {
        if (element.hasAttribute(ATTR_ROOT_ID)) {
            // if the element has this attribute, there has been a transformation before
            // and the previous root has to be unmounted
            this.reactRootStore.find((root) => root.id === element.getAttribute(ATTR_ROOT_ID))
                .root
                .unmount();
            element.removeAttribute(ATTR_ROOT_ID);
        }
        const root = ReactDOM.createRoot(element);
        const id = uuid();
        element.setAttribute(ATTR_ROOT_ID, id);
        this.reactRootStore.push({
            id,
            root,
        });
        return root;
    }

    /**
     * Handles each observation done inside the bootstrap method (if candidate is configured for lazy loading)
     * @param entries
     * @param observer
     * @returns {*}
     */
    intersectionHandler(entries, observer) {
        entries.filter((entry) => entry.isIntersecting).forEach((entry) => {
            observer.unobserve(entry.target);
            this.render(entry.target);
        });
    }

    set insideEditor(boolean) {
        this.#insideEditor = boolean;
    }

    get insideEditor() {
        return this.#insideEditor;
    }

    set reactRootCandidates(candidates) {
        this.#reactRootCandidates = candidates;
    }

    get reactRootCandidates() {
        return this.#reactRootCandidates;
    }

    set reactRootStore(store) {
        this.#reactRootStore = store;
    }

    get reactRootStore() {
        return this.#reactRootStore;
    }

    get intersectionObserver() {
        return this.#intersectionObserver;
    }

    set intersectionObserver(observer) {
        this.#intersectionObserver = observer;
    }

    get globalNotifier() {
        return this.#globalNotifier;
    }

    set globalNotifier(_) {
        this.#globalNotifier = _;
    }

    get globalNotificationTimeout() {
        return this.#globalNotificationTimeout;
    }

    set globalNotificationTimeout(_) {
        this.#globalNotificationTimeout = _;
    }
}
new ReactLoader();
